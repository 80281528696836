import React, {Component} from 'react';


class Hashtag extends Component {
    render() {
        return (
            <div style={{
                marginTop: '45px',
                marginBottom: '75px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                maxWidth:'600px'
            }}>

                <div className={"hashTag"}>meditation music</div>
                <div className={"hashTag"}>relaxing sounds</div>
                <div className={"hashTag"}>nature sounds</div>
                <div className={"hashTag"}>calm your mind</div>
                <div className={"hashTag"}>music against anxiety</div>
                <div className={"hashTag"}>free the mind</div>
            </div>
        );
    }
}

export default Hashtag;
