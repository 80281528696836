import React, {Component} from 'react';


class DownloadItNow extends Component {
    render() {
        return (
            <div className={"FullWidth Cover"} style={{
                minHeight: '290px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: "#eeeeee"
            }}>
                <div style={{fontWeight: 'bold', color: 'rgba(10,10,10,0.7)'}}>Our app:</div>
                <h1 style={{color: 'black', fontSize: '20px'}}>Relaxing Music - calm your mind</h1>

                <a href={"https://play.google.com/store/apps/details?id=com.kaleidosstudio.meditation_relax_app"}
                   className={"ButtonActMain"}>Get it on Google Play</a>

                <a href={"https://itunes.apple.com/us/app/relax-and-meditation-music/id1454934819"}
                   className={"ButtonActMain"}>Get it on iTunes</a>
            </div>
        );
    }
}

export default DownloadItNow;
