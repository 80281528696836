import React, {Component} from 'react';


class FeaturesMusic extends Component {

    constructor(props) {
        super(props);
        this.items = [];

        this.items.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/harp_music.jpg/get/250x250.jpg",
            "title": "Calming Music sounds",
            "desc": "Harp music"
        });

        this.items.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/piano_music.jpg/get/250x250.jpg",
            "title": "Calming Music sounds",
            "desc": "Piano music"
        });

        this.items.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/meditation_music.jpg/get/250x250.jpg",
            "title": "Calming Music sounds",
            "desc": "Meditation music"
        });

        this.items.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/flute_music.jpg/get/250x250.jpg",
            "title": "Calming Music sounds",
            "desc": "Flute music"
        });


    }


    render() {
        return (
            <div>
                <div style={{width: '100%', height: '35px'}}/>
                <h1>Meditation and Relaxing music</h1>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginTop: '15px',
                    marginBottom: '25px'
                }}>
                    {this.items.map((obj, index) => {
                        return <div key={index} className={"RoundItem HalfRound"}>
                            <div className={"Absolute Animation  Cover Image"}
                                 style={{backgroundImage: 'url(' + obj.image + ')'}}/>
                            <div className={"Absolute Animation AlphaBlack"}/>
                            <h1 className={"Animation"}>{obj.title}</h1>
                            <p className={"Animation"}>{obj.desc}</p>
                        </div>
                    })}


                </div>
            </div>
        );
    }
}

export default FeaturesMusic;
