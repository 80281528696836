import React, {Component} from 'react';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';


class Gallery extends Component {
    constructor(props) {
        super(props);


        this.gallery = [];
        this.gallery.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/lake.jpg/get/1000x0.jpg",
            "title": "Relaxing Music",
            "desc": "calm your mind"
        });

        this.gallery.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/mountain.jpg/get/1000x0.jpg",
            "title": "Have a break",
            "desc": "just relax and meditate"
        });

        this.gallery.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/dream.jpg/get/1000x0.jpg",
            "title": "Nature sounds",
            "desc": "and relaxing music"
        });

        this.gallery.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/flower.jpg/get/1000x0.jpg",
            "title": "Mix the sounds",
            "desc": "create your relaxing music"
        });
        this.gallery.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/dreamcatcher.jpg/get/1000x0.jpg",
            "title": "High quality dream pictures",
            "desc": "for a perfect stress relief"
        });

        this.gallery.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/forest.jpg/get/1000x0.jpg",
            "title": "Beautiful relaxing music",
            "desc": "to sleep well against insomnia"
        });


        this.GoNext = this.GoNext.bind(this);
        this.GoNextText = this.GoNextText.bind(this);

        this.state = {showImage: 0, showText: -1};
        this.ImageTime = 5000;
        this.TextTime = 2000;

    }

    GoNextText() {
        let next = this.state.showText + 1;
        if (next >= this.gallery.length) {
            next = 0;
        }

        setTimeout(() => {
            this.setState({showText: next}, () => {
                this.GoNext();
            });
        }, this.TextTime);
    }

    GoNext() {
        let next = this.state.showImage + 1;
        if (next >= this.gallery.length) {
            next = 0;
        }

        setTimeout(() => {
            this.setState({showImage: next}, () => {
                this.GoNextText();
            });
        }, this.ImageTime);
    }


    componentDidMount() {
        this.setState({showText: 0}, () => {
            this.GoNext();
        });
    }

    render() {

        return <div className={"galleryContainer"}>
            {this.gallery.map((obj, index) => {

                let text = null;

                if (index === 0) {
                    text = <div>
                        <Flip left when={this.state.showText === index}>
                            <h1 className={"galleryText galleryTextTitle"}>{obj.title}</h1>
                        </Flip>

                        <Bounce when={this.state.showText === index}>
                            <p className={"galleryText galleryTextDesc"}>{obj.desc}</p>
                        </Bounce>
                    </div>;
                }


                if (index === 1) {
                    text = <div>
                        <Fade top when={this.state.showText === index}>
                            <h1 className={"galleryText galleryTextTitle"}>{obj.title}</h1>
                        </Fade>

                        <Zoom when={this.state.showText === index}>
                            <p className={"galleryText galleryTextDesc"}>{obj.desc}</p>
                        </Zoom>
                    </div>;
                }


                if (index === 2) {
                    text = <div>
                        <Slide top when={this.state.showText === index}>
                            <h1 className={"galleryText galleryTextTitle"}>{obj.title}</h1>
                        </Slide>

                        <Slide top when={this.state.showText === index}>
                            <p className={"galleryText galleryTextDesc"}>{obj.desc}</p>
                        </Slide>
                    </div>;
                }

                if (index === 3) {
                    text = <div>
                        <Slide top when={this.state.showText === index}>
                            <h1 className={"galleryText galleryTextTitle"}>{obj.title}</h1>
                        </Slide>

                        <Slide bottom when={this.state.showText === index}>
                            <p className={"galleryText galleryTextDesc"}>{obj.desc}</p>
                        </Slide>
                    </div>;
                }

                if (index === 4) {
                    text = <div>
                        <Slide left when={this.state.showText === index}>
                            <h1 className={"galleryText galleryTextTitle"}>{obj.title}</h1>
                        </Slide>

                        <Slide right when={this.state.showText === index}>
                            <p className={"galleryText galleryTextDesc"}>{obj.desc}</p>
                        </Slide>
                    </div>;
                }

                if (index === 5) {
                    text = <div>
                        <Slide top when={this.state.showText === index}>
                            <h1 className={"galleryText galleryTextTitle"}>{obj.title}</h1>
                        </Slide>

                        <Slide bottom when={this.state.showText === index}>
                            <p className={"galleryText galleryTextDesc"}>{obj.desc}</p>
                        </Slide>
                    </div>;
                }

                return <div key={index} className={"galleryContainerSingle Absolute"}>

                    <Fade when={this.state.showImage === index}>
                        <div className={"FullWidth Cover"} style={{backgroundImage: 'url(' + obj.image + ')'}}/>

                        <div className={"Absolute"}>
                            {text}
                        </div>

                    </Fade>
                </div>
            })}</div>

    }
}

export default Gallery;
