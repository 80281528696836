import React, {Component} from 'react';


class Footer extends Component {
    render() {
        return (
            <div style={{minHeight: '250px'}}>

                <div style={{width: '100%', height: '25px'}}/>

                <h1 style={{fontSize: '18px'}}>Relax and meditation music - calm your mind</h1>

                <div style={{width: '100%', height: '25px'}}/>
                <a href="mailto:info@kaleidosstudio.com">info@kaleidosstudio.com</a>
                <div style={{width: '100%', height: '15px'}}/>
                <a href={"https://www.naturallifeapp.com/"}>naturallifeapp.com</a>
            </div>
        );
    }
}

export default Footer;
