import React, {Component} from 'react';



class TopBar extends Component {
    render() {
        return (
            <div className={"TopBar"}>

                <h1>Relaxing Music</h1>

            </div>
        );
    }
}

export default TopBar;
