import React, {Component} from 'react';
import './App.css';
import TopBar from "./pages/TopBar";
import Gallery from "./pages/Gallery";
import FeaturesSounds from "./pages/FeaturesSounds";
import OurApps from "./pages/OurApps";
import Footer from "./pages/Footer";
import FeaturesMusic from "./pages/FeaturesMusic";
import Hashtag from "./pages/Hashtag";
import DownloadItNow from "./pages/DownloadItNow";


class App extends Component {
    render() {
        return (
            <div className="App">

                <TopBar/>
                <Gallery/>
                <DownloadItNow/>
                <FeaturesSounds/>
                <FeaturesMusic/>
                <Hashtag/>
                <OurApps/>
                <Footer/>

            </div>
        );
    }
}

export default App;
