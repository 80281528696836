import React, {Component} from 'react';


class OurApps extends Component {


    constructor(props) {
        super(props);
        this.items = [];

        this.items.push({
            "image": "https://cloudimage.zefiroapp.com/v1/our_works/s3/kaleidosstudio.ourworks/media/meditation_relax_app.jpg/get/300x300.jpg",
            "title": "Meditation Relax music",
            "desc": "Sounds of nature and relaxing music for a peaceful mind",
            "android": "https://play.google.com/store/apps/details?id=com.kaleidosstudio.meditation_relax_app",
            "ios": "https://itunes.apple.com/us/app/relax-and-meditation-music/id1454934819"
        });
        this.items.push({
            "image": "https://cloudimage.zefiroapp.com/v1/our_works/s3/kaleidosstudio.ourworks/media/rimedi_naturali.jpg/get/300x300.jpg",
            "title": "Natural Remedies",
            "desc": "Your handbook about natural home remedies",
            "android": "https://play.google.com/store/apps/details?id=com.kaleidosstudio.natural_remedies",
            "ios": "https://itunes.apple.com/us/app/the-natural-remedies/id1081776412"
        });


    }

    render() {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: '#f6f6f6'
            }}>
                <h2>Our Apps</h2>
                <div style={{
                    width: '100%',
                    maxWidth: '900px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    marginTop: '25px',
                    marginBottom: '25px'
                }}>


                    {this.items.map((obj, index) => {

                        let downloadButtonAndroid = null;
                        let downloadButtoniOS = null;


                        if(obj.android !== "")
                        {
                            downloadButtonAndroid =  <a href={obj.android} className={"ButtonAct"}>Google Play</a>
                        }

                        if(obj.ios !== "")
                        {
                            downloadButtoniOS =  <a href={obj.ios} className={"ButtonAct"}>iTunes</a>
                        }

                        return <div key={index} style={{
                            width: '270px',
                            backgroundColor: 'white',
                            border: '1px solid rgba(10,10,10,0.1)',
                            textAlign: 'left',
                            margin: '15px'
                        }}>
                            <div className={"Cover"} style={{
                                width: '100%',
                                height: '220px',
                                backgroundImage: 'url(' + obj.image + ')'
                            }}/>

                            <div style={{padding: '15px'}}>

                                <h1 style={{fontSize: '17px', margin: 0}}>{obj.title}</h1>
                                <div style={{fontSize: '13px'}}>{obj.desc} </div>

                                {downloadButtonAndroid}
                                {downloadButtoniOS}

                            </div>
                        </div>
                    })}


                </div>
            </div>
        );
    }
}

export default OurApps;
