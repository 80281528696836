import React, {Component} from 'react';


class FeaturesSounds extends Component {

    constructor(props) {
        super(props);
        this.items = [];

        this.items.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/water_nature_sound.jpg/get/250x250.jpg",
            "title": "Nature Sounds",
            "desc": "Water, to sleep well"
        });

        this.items.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/fire_sound.jpg/get/250x250.jpg",
            "title": "Nature Sounds",
            "desc": "Fire to boost energy"
        });

        this.items.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/wind_sound.jpg/get/250x250.jpg",
            "title": "Nature Sounds",
            "desc": "Breeze to dream"
        });

        this.items.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/bird_nature_sound.jpg/get/250x250.jpg",
            "title": "Nature Sounds",
            "desc": "Birds to wake up"
        });

        this.items.push({
            "image": "https://cloudimage.zefiroapp.com/v1/relaxing_music/s3/app.relax/www_media/waves_nature_sound.jpg/get/250x250.jpg",
            "title": "Nature Sounds",
            "desc": "Sea to calm the mind"
        });
    }

    render() {
        return (
            <div>
                <div style={{width: '100%', height: '25px'}}/>
                <h1>Sounds of the Nature</h1>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginTop: '15px',
                    marginBottom: '25px'
                }}>
                    {this.items.map((obj, index) => {
                        return <div key={index} className={"RoundItem RoundItemAnimation"}>
                            <div className={"Absolute Animation  Cover Image"}
                                 style={{backgroundImage: 'url(' + obj.image + ')'}}/>
                            <div className={"Absolute Animation AlphaBlack "}/>
                            <h1 className={"Animation"}>{obj.title}</h1>
                            <p className={"Animation"}>{obj.desc}</p>
                        </div>
                    })}


                </div>
            </div>
        );
    }
}

export default FeaturesSounds;
